import React, {
    useContext, useEffect, useState
} from 'react';
import UserContext, { PusherContext, UserProfileContext } from '../utils/getContext';
import { checkIsPartner } from '../utils/memo';
import { usePusher } from '../utils/pusher.utills';

const AuthExternal = ({ children }) => {
    const {
        userToken, setUserToken, lang
    } = useContext(UserContext);

    const [profileData, setProfileData] = useState(false);
    const [loaded, setLoaded] = useState(!userToken);
    const { pusherClient } = usePusher(loaded && userToken, lang);

    useEffect(() => {
        if (!userToken) {
            setProfileData(false);
            setLoaded(true)
        } else {
            (async () => {
                const data = await checkIsPartner(userToken);
                if (data?.data?.data) {
                    setProfileData(data?.data?.data);
                    setLoaded(true)
                } else {
                    if (data === 401){
                        await setUserToken(false);
                    }
                    setLoaded(true)
                }
            })();
        }
    }, [userToken, setUserToken]);

    return (
        <>
            <PusherContext.Provider value={{ pusherClient }}>
                <UserProfileContext.Provider value={{
                    profileData, loaded, mainPage: true
                }}>
                    {children}
                </UserProfileContext.Provider>
            </PusherContext.Provider>
        </>
    );
};

export default AuthExternal;